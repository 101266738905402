import { TermFacet } from '../../../../../types/result/TermFacet';

export const getFilterIdentifiers = (facets?: TermFacet[]): { identifier: FilterIdentifier; text: string }[] => {
  const identifiers = [
    { identifier: 'TP_PRODUCT_TYPE' as FilterIdentifier, text: 'Type' },
    { identifier: 'TP_PRODUCT_STYLE' as FilterIdentifier, text: 'Style' },
    { identifier: 'TP_PRODUCT_FIT' as FilterIdentifier, text: 'Fit' },
    { identifier: 'TP_SIZE' as FilterIdentifier, text: 'Size' },
    { identifier: 'TP_MASTER_COLOUR' as FilterIdentifier, text: 'Colour' },
    { identifier: 'TP_CUFF' as FilterIdentifier, text: 'Cuff type' },
    { identifier: 'TP_SLEEVE_LENGTH' as FilterIdentifier, text: 'Sleeve length' },
    { identifier: 'TP_FABRIC' as FilterIdentifier, text: 'Fabric' },
    { identifier: 'TP_FABRIC_PATTERN' as FilterIdentifier, text: 'Pattern' },
    { identifier: 'TP_FABRIC_WEAVE' as FilterIdentifier, text: 'Weave' },
  ];

  if (facets) {
    return identifiers.filter(({ identifier }) => facets.map((f) => f.identifier).includes(`attributes.${identifier}`));
  }

  return identifiers;
};

export type FilterIdentifier =
  | 'MASTER_COLOUR'
  | 'TP_FIT'
  | 'TP_SLEEVE'
  | 'TP_CUFF'
  | 'TP_FABRIC'
  | 'TP_PATTERN'
  | 'SIZE'
  | 'TP_STYLE';
