import { Product, UIProduct } from '@Types/product/Product';
import { ProductVariantOptionsList } from '@Types/product/ProductVariantOption';
import { LineItem } from '../../../../../types/cart/LineItem';

const optionsNames = {
  colour: 'Colour',
  fabric: 'Fabric Type',
  fit: 'Product Fit',
  cuff: 'Cuff Type',
  size: 'Size',
  sleeve: 'Sleeve Length',
  legLength: 'Leg Length',
};

export const productLevelOptions = ['colour', 'fit', 'cuff', 'fabric'];
export const optionsOrder = ['fit', 'colour', 'fabric', 'cuff', 'sleeve', 'legLength', 'size'];
export const mustShowOptions = ['size'];

// Specific TP request on the order of some option values, if more specific option orders are needed, just add them in the array
export const optionValuesOrder = [
  'Single cuff',
  'Double cuff',
  'Short',
  'Regular',
  'Long',
  'XXXS',
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
  'XXXXL',
  'XXXXXL',
];

export function generateProductOptions(uiProduct: UIProduct) {
  const options: ProductVariantOptionsList[] = [];
  uiProduct.variants?.forEach((variant) => {
    for (const [key, value] of Object.entries(variant)) {
      if (value && optionsNames[key] && !options.some((option) => option.key === key))
        options.push({
          name: optionsNames[key],
          key,
          selected: '',
          previouslySelected: '',
          options: {},
        });
    }
  });
  const sortedOptions = options.sort((a, b) => optionsOrder.indexOf(a.key) - optionsOrder.indexOf(b.key));
  return sortedOptions;
}

/**
 * Generate a list of options and make initial selections.
 * Options driven by the main product will be selected from the product.
 * Variant level options will have the first available option selected.
 */
export function selectInitialOptions(uiProduct: UIProduct) {
  // First available option of each options list is selected
  // and used in the grouping for every next option list that comes
  const options: ProductVariantOptionsList[] = generateProductOptions(uiProduct);

  const allOutOfStock = uiProduct.variants.find((vrnt) => vrnt.isInStock);
  if (!allOutOfStock) {
    uiProduct.allOutOfStock = true;
  }

  options.forEach((option, index) => {
    if (index === 0) {
      option.options = groupProductOptionsBy(uiProduct.variants, option.key);
    } else {
      const previousOptionsList = Object.values(options[index - 1].options);

      const previousValue = previousOptionsList.filter((optionsList) =>
        optionsList.some((opt) => opt[options[index - 1].key] === options[index - 1].selected),
      );

      option.options = groupProductOptionsBy(
        previousValue.length > 0 ? previousValue[0] : previousOptionsList[0],
        option.key,
      );
    }

    /**
     *   Product level options should have a correct option value selected based on the product loaded,
     *   Non-product level options have the first available option selected on inital load, except the size, which is not automatically selected on intial load
     *   unless there is only one size available
     */
    if (option.key === 'size' && Object.keys(option.options).length > 1) {
      option.selected = '';
      option.previouslySelected = '';
      option.shouldBeDeselectedInFrontend = true;
    } else {
      let optionValues: string[];
      if (productLevelOptions.includes(option.key)) {
        optionValues = Object.keys(option.options).filter(
          (value) =>
            value ===
            uiProduct.variants.find((variant) =>
              uiProduct.id.toLowerCase().includes(variant.productId.toLowerCase()),
            )?.[option.key],
        );
      } else {
        optionValues = Object.keys(option.options);
      }

      option.selected = optionValues[0] ?? '';
      option.previouslySelected = optionValues[0] ?? '';
      option.shouldBeDeselectedInFrontend = false;
    }
  });

  return options;
}

export function selectOption(optionListKey, optionKey, optionsLists) {
  const options: ProductVariantOptionsList[] = [...optionsLists];
  const selectedOptionIndex = options.findIndex((option) => option.key === optionListKey);

  // Logic for filtering the options lists based on the selection
  options.forEach((option, index) => {
    // We filter only through the options that come after the changed one,
    // no need to filter ones that come before
    if (index === selectedOptionIndex) {
      option.previouslySelected = option.selected;
      option.selected = optionKey;
      option.shouldBeDeselectedInFrontend = false;
    } else if (index > selectedOptionIndex) {
      const parentOptionList = options[index - 1];
      const parentSelection = parentOptionList.selected;

      // STOCK IS NOT TAKEN INTO CONSIDERATION IN THIS STEP OF THE PROCES, WE SELECT THE OPTION EVENTHOUGH IT MIGHT BE OUT OF STOCK
      // STOCK IS TAKEN INTO CONSIDERATION IN THE PRODUCT-OPTION COMPONENT
      /**
       *  USER SELECTS AN OPTION
       *  a. IF THE OLD OPTION EXISTS IN THE NEW SELECTION, KEEP IT
       *  b. IF THE OLD OPTION DOES NOT EXIST
       *      b.1 IF IT IS A PRODUCT LEVEL OPTION
       *        - SELECT THE FIRST ONE AVAILABLE
       *      b.2 IF IT IS A VARIANT LEVEL OPTION
       *        - SELECT THE FIRST ONE AVAILABLE
       *        - SET SIZE OPTION AS DESELECTED IN FRONTED
       */

      option.options = groupProductOptionsBy(parentOptionList.options[parentSelection], option.key);

      // a.
      // OLD OPTION EXISTS IN NEW OPTIONS
      // NOTHING TO DO, SELECTED VALUE STILL APPLIES
      if (!Object.keys(option.options).includes(option.selected)) {
        // b.
        // OLD OPTION DOES NOT EXIST IN NEW OPTIONS
        option.previouslySelected = option.selected;
        option.selected = Object.keys(option.options)[0];

        // b.2
        // BUT OPTION IS A VARIANT LEVEL ONE, SO WE NEED TO DESELCT IT IN FRONTEND, SELECT THE FIRST ONE FOR FILTERING SO WE CAN CONTINUE ON
        if (!productLevelOptions.includes(option.key)) {
          options.find((opt) => opt.key === 'size').shouldBeDeselectedInFrontend = true;
        }
      }
    }
  });

  return options;
}

export function getCurrentSelectedVariant(options) {
  // Compile list of all variants
  let variants = [];
  options.forEach((option) => {
    option.options[option.selected]?.forEach((variant) => {
      variants.push(variant);
    });
  });
  // Filter variant list
  options.forEach((option) => {
    if (option.selected) {
      variants = variants.filter((variant) => {
        return option.selected === variant[option.key];
      });
    }
  });
  // Return selected variant
  return variants.length > 0 ? variants[0] : null;
}

export const groupProductOptionsBy = function (array, key) {
  // TODO: Better sorting? Needs to be sorted so that the option lists are built and used correctly
  const xs = array?.sort((a, b) => {
    if (parseFloat(a[key])) {
      return parseFloat(a[key]) > parseFloat(b[key]) ? 1 : -1;
    } else {
      if (optionValuesOrder.includes(a[key]) && optionValuesOrder.includes(b[key])) {
        return optionValuesOrder.indexOf(a[key]) > optionValuesOrder.indexOf(b[key]) ? 1 : -1;
      }
      return a[key] > b[key] ? 1 : -1;
    }
  });

  return xs
    ? xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {})
    : [];
};

export const getIsProductInStock = (optionsLists: ProductVariantOptionsList[], lineItems: LineItem[]) => {
  if (!optionsLists.find((o) => o.key === 'size')) return true;

  return Object.values(optionsLists.find((o) => o.key === 'size')?.options ?? {})
    .map((option) => {
      if (option[0].availableQuantity === 0) return false;

      const lineItem = lineItems?.find((item) => item?.variant?.sku === option[0].sku);
      return lineItem ? lineItem.count > option[0].availableQuantity : true;
    })
    .includes(true);
};
