import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { AlgoliaProduct, Product } from '@Types/product/Product';
import classNames from 'classnames';
import TagManager from 'helpers/googleTagManager/googleTag';
import { useFormat } from 'helpers/hooks/useFormat';
import BackToTopIcon from '../../../../../frontend/public/images/back_to_top.png';
import ProductCard from '../product-cards';
import styles from './product-list.module.scss';
interface Props {
  products: AlgoliaProduct[];
  filtering?: boolean;
  gridLayout: any;
  query?: any;
}

const List: React.FC<Props> = ({ products, filtering, gridLayout, query }) => {
  //i18n messages
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  const [scrollThreshold, setScrollThresHold] = useState(0);

  useEffect(() => {
    function onScroll() {
      if (scrollThreshold < window.scrollY && !showBackToTopButton) {
        const firstProductCard = document?.querySelector('[data-testid^="product-card"]') as HTMLElement;
        const temp =
          firstProductCard?.offsetHeight * 3 + firstProductCard?.getBoundingClientRect().top + window.scrollY;
        if (temp !== scrollThreshold) {
          setScrollThresHold(temp);
        }
        setShowBackToTopButton(true);
      } else if (scrollThreshold >= window.scrollY && showBackToTopButton) {
        setShowBackToTopButton(false);
      }
    }
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [showBackToTopButton]);

  const goBackToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const addSelectItemEvent = (product: AlgoliaProduct, index: number) => {
    const tagManager = new TagManager();
    tagManager.selectItem(product, query?.category ?? 'Search > ' + query?.query, index);
  };

  const sizes = () => {
    switch (gridLayout) {
      case 1:
        return '100vw';
      case 2:
        return '50vw';
      case 3:
        return '33vw';
      case 4:
        return '25vw';
      default:
        return '100vw';
    }
  };

  return (
    <div className={styles.productGridContainer}>
      <h2 className="sr-only">{formatProductMessage({ id: 'products', defaultMessage: 'Products' })}</h2>
      <div className={styles.listWrapper}>
        <ul
          className={classNames(styles.productGrid, {
            [styles.productGrid__oneCol]: gridLayout === 1,
            [styles.productGrid__twoCol]: gridLayout === 2,
            [styles.productGrid__threeCol]: gridLayout === 3,
            [styles.productGrid__fourCol]: gridLayout === 4,
          })}
          data-testid="product-list"
        >
          {products?.map((product, index) => (
            <ProductCard
              product={product}
              priority={index < gridLayout}
              sizes={sizes()}
              gridLayout={gridLayout}
              key={`${product.productId}-${index}`}
              addSelectItemEvent={() => addSelectItemEvent(product, index)}
            />
          ))}
        </ul>
        {showBackToTopButton && (
          <span className={styles.backToTopIcon} onClick={goBackToTop}>
            <Image unoptimized src={BackToTopIcon} alt="back-to-top-icon" width={48} height={48} />
          </span>
        )}
      </div>
    </div>
  );
};

export default List;
