import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { TermFacet } from '@Types/result/TermFacet';
import classNames from 'classnames';
import { useFormat } from 'helpers/hooks/useFormat';
import { LayoutContext } from '../../../frontastic/provider/layout';
import ChevronIcon from '../../icons/chevron';
import CloseIcon from '../../icons/close';
import { sortOptions } from './constants';
import Drawer from './drawer';
import styles from './filters.module.scss';
import { getFilteredFacets } from './helpers/getFilteredFacets';
import { FilterIdentifier, getFilterIdentifiers } from './helpers/getFilterIdentifiers';
import { getFiltersFromQuery } from './helpers/getFiltersFromQuery';
import { getSortFromQuery } from './helpers/getSortFromQuery';

type FiltersProps = {
  facets: TermFacet[];
  numOfItems: number;
  toggleGridLayout: any;
  gridLayout: any;
};

export type Filters = {
  [key: string]: string[];
};

export type Sort = {
  name: string;
  value: string;
  label: {
    id: string;
    defaultMessage: string;
  };
};

const RESULTS_CONTAINER_LIMIT = 90;

const Filters: FC<FiltersProps> = ({ facets, numOfItems, gridLayout, toggleGridLayout }) => {
  const router = useRouter();
  const layout = useContext(LayoutContext);

  const menuRef = useRef(null);
  const [openedFilter, setOpenedFilter] = useState<FilterIdentifier | null>();
  const [selectedFilters, setSelectedFilters] = useState<Filters>(getFiltersFromQuery(router.query));

  const [isSortOpen, setIsSortOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState<Sort>(getSortFromQuery(router.query));

  const [openedDrawer, setOpenedDrawer] = useState<undefined | 'sort' | 'filter'>(undefined);
  const [drawerFilters, setDrawerFilters] = useState<Filters>({});

  const selectedFiltersRef = useRef<HTMLDivElement>(null);
  const [isResultExpanded, setIsResultExpanded] = useState(false);
  const isResultExpandable = selectedFiltersRef?.current
    ? selectedFiltersRef.current.scrollHeight > RESULTS_CONTAINER_LIMIT
    : false;

  const filteredFacets = getFilteredFacets(facets, selectedFilters);
  const filterIdentifiers = getFilterIdentifiers(filteredFacets);

  const { formatMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatCommon } = useFormat({ name: 'common' });

  useEffect(() => {
    setSelectedFilters(getFiltersFromQuery(router.query));
    setSelectedSort(getSortFromQuery(router.query));
  }, [router.query]);

  useEffect(() => {
    if (openedDrawer) {
      setOpenedDrawer(undefined);
      setOpenedFilter(null);
    }
  }, [layout.isLargeDesktop, selectedSort]);

  useEffect(() => {
    if (openedDrawer) {
      setDrawerFilters({ ...selectedFilters });
    }
  }, [openedDrawer]);

  useEffect(() => {
    const outsideClickHandler = (e: MouseEvent) => {
      if (!menuRef?.current?.contains(e.target)) {
        setIsSortOpen(false);
        setOpenedFilter(null);
      }
    };

    if (layout.isLargeDesktop && (openedFilter || isSortOpen)) {
      window.addEventListener('click', outsideClickHandler);
    } else {
      window.removeEventListener('click', outsideClickHandler);
    }

    return () => {
      window.removeEventListener('click', outsideClickHandler);
    };
  }, [openedFilter, isSortOpen]);

  const toggleFilterDropdown = (identifier: FilterIdentifier) => {
    if (isSortOpen) setIsSortOpen(false);
    setOpenedFilter((currentIdentifier) => {
      return currentIdentifier !== identifier ? identifier : null;
    });
  };

  const toggleSortDropdown = () => {
    if (openedFilter) setOpenedFilter(null);
    setIsSortOpen(!isSortOpen);
  };

  const handleFilterClick = (attribute: string, term: string) => {
    const newFilters = { ...selectedFilters };
    if (newFilters[attribute].includes(term)) {
      newFilters[attribute] = newFilters[attribute].filter((t: string) => t != term);
    } else {
      newFilters[attribute] = [...newFilters[attribute], term];
    }
    setSelectedFilters(newFilters);
    refreshProducts(newFilters, selectedSort);
  };

  const handleSortClick = (sort: Sort) => {
    setSelectedSort(sort);
    refreshProducts(selectedFilters, sort);
  };

  const handleClearFiltersClick = () => {
    setSelectedFilters(getFiltersFromQuery({}));
    setOpenedDrawer(undefined);
    refreshProducts({}, selectedSort);
  };

  const handleDrawerClearClick = (identifier: FilterIdentifier) => {
    setDrawerFilters({ ...drawerFilters, [identifier]: [] });
  };

  const handleDrawerFilterClick = (attribute: string, term: string) => {
    const newFilters = { ...drawerFilters };
    if (newFilters[attribute].includes(term)) {
      newFilters[attribute] = newFilters[attribute].filter((t: string) => t != term);
    } else {
      newFilters[attribute] = [...newFilters[attribute], term];
    }
    setDrawerFilters(newFilters);
  };

  const handleApplyFiltersClick = () => {
    setSelectedFilters({ ...drawerFilters });
    setOpenedDrawer(undefined);
    setOpenedFilter(null);
    refreshProducts(drawerFilters, selectedSort);
  };

  const refreshProducts = (filters: Filters, sort: Sort) => {
    const { query } = router;
    const params = [];

    Object.entries(filters).forEach(([identifier, values]: [string, string[]]) => {
      if (values.length) {
        params.push({
          key: `facets[attributes.${identifier}][terms]`,
          value: values.join(','),
        });
      }
    });
    if (sort) {
      params.push({
        key: `sortAttributes[0][${sort.name}]`,
        value: sort.value,
      });
    }

    const newParams = {};
    params.forEach((object) => {
      newParams[object.key] = object.value;
    });

    router.push({ pathname: router.asPath.split('?')[0], query: { query: query['query'], ...newParams } });
  };

  const transformLabel = (attrIdentifier: string, label: any) => {
    if (attrIdentifier === 'SIZE' && !isNaN(label)) {
      return `${label.toLowerCase()} "`;
    } else {
      return label.toLowerCase();
    }
  };

  const getButtonStyle = () => {
    const MIN_WIDTH = 260;

    if (!selectedFiltersRef?.current) return { width: 'auto', display: 'none' };
    if (!isResultExpandable) return { width: 'auto' };

    const container = selectedFiltersRef.current;
    const numOfChildren = container.childNodes.length;
    let width = 0;
    for (let i = numOfChildren - 2; 0 <= i; i--) {
      const child = selectedFiltersRef.current.childNodes[i] as HTMLElement;

      const isChildInThirdRow = child.offsetTop + child.clientHeight > RESULTS_CONTAINER_LIMIT;
      if (isChildInThirdRow) continue;

      if (container.clientWidth - child.offsetLeft >= MIN_WIDTH) {
        width = container.clientWidth - child.offsetLeft;
        break;
      }
    }

    return { width };
  };

  // TODO: if there are no filters don't show them
  return (
    <div
      data-testid="filters_container"
      className={styles.container}
      style={{
        top: layout.headerOffset + 'px',
      }}
    >
      <div ref={menuRef} className={styles.menuWrapper}>
        <div className={classNames(styles.menu, { [styles.menu__desk]: layout.isLargeDesktop })}>
          {/* Second condition covers the case for initial ssr response when we dont have full information on screen size */}
          {layout.isLargeDesktop || (layout.isDesktop && layout.pageWidth === 0) ? (
            filterIdentifiers.map(({ identifier: attrIdentifier, text }) => (
              <button
                key={attrIdentifier}
                className={classNames(styles.filterBtn, {
                  [styles.filterBtn__open]: attrIdentifier === openedFilter,
                })}
                onClick={() => toggleFilterDropdown(attrIdentifier)}
              >
                {text + (selectedFilters[attrIdentifier]?.length ? ` (${selectedFilters[attrIdentifier].length})` : '')}
                <ChevronIcon className={styles.chevron} />
              </button>
            ))
          ) : (
            <button className={styles.filterBtn} onClick={() => setOpenedDrawer('filter')}>
              {`${formatMessage({ id: 'plp.filters', defaultMessage: 'filters' })}${
                Object.values(selectedFilters).flat().length ? ` (${Object.values(selectedFilters).flat().length})` : ''
              }`}
            </button>
          )}

          <div
            className={classNames(styles.gridLayoutBtn, { [styles.gridLayoutBtn__desk]: layout.isLargeDesktop })}
            onClick={() => toggleGridLayout()}
          >
            <span className={styles.gridLayoutBtn__text}>{formatCommon({ id: 'grid', defaultMessage: 'Grid' })}</span>
            <div
              className={classNames(styles.gridLayoutBtn__icon, {
                [styles.gridLayoutBtn__icon__inverted]: gridLayout === 1,
              })}
            >
              {Array.from({ length: gridLayout <= 2 ? 2 : gridLayout }, (_, index) => (
                <div
                  key={'line' + index}
                  className={classNames(styles.gridLayoutBtn__line, {
                    [styles.gridLayoutBtn__line__mobile]: gridLayout <= 2,
                    [styles.gridLayoutBtn__line__mobileInverted]: gridLayout === 1,
                  })}
                ></div>
              ))}
            </div>
          </div>

          <button
            className={classNames(styles.sortBtn, { [styles.sortBtn__open]: isSortOpen })}
            onClick={layout.isLargeDesktop ? toggleSortDropdown : () => setOpenedDrawer('sort')}
          >
            {formatMessage({ id: 'plp.sort', defaultMessage: 'sort by' })}
            {selectedSort?.label && `: ${formatMessage(selectedSort.label)}`}
            {layout.isLargeDesktop && <ChevronIcon className={styles.chevron} />}
          </button>
        </div>

        {layout.isLargeDesktop && (isSortOpen || openedFilter) && (
          <form className={styles.dropdown}>
            <ul>
              {openedFilter &&
                filteredFacets
                  .find((facet) => facet.identifier === `attributes.${openedFilter}`)
                  ?.terms.filter(({ count }) => count > 0)
                  .map(({ identifier: termIdentifier, key, label, count }) => (
                    <li
                      key={key}
                      className={styles.filterOption}
                      onClick={() => handleFilterClick(openedFilter, termIdentifier)}
                    >
                      <input
                        checked={selectedFilters[openedFilter]?.includes(termIdentifier)}
                        type="checkbox"
                        name={openedFilter}
                        value={key}
                        onChange={() => {}}
                      />
                      <label htmlFor={key}>{`${transformLabel(openedFilter, label)} (${count})`}</label>
                    </li>
                  ))}
              {isSortOpen &&
                sortOptions.map((option, index) => (
                  <li
                    key={index}
                    className={classNames(styles.sortOption, {
                      [styles.sortOption__selected]: selectedSort?.label === option.label,
                    })}
                    onClick={() => handleSortClick(option)}
                  >
                    {formatMessage(option.label)}
                  </li>
                ))}
            </ul>
          </form>
        )}
      </div>

      {/* Second condition covers the case for initial ssr response when we dont have full information on screen size */}
      {(layout.isLargeDesktop || (layout.isDesktop && layout.pageWidth === 0)) && (
        <div className={styles.results}>
          <div className={styles.productNumber}>
            <span>{numOfItems}</span>
            &nbsp;
            {numOfItems > 1
              ? formatMessage({
                  id: 'plp.product.number.multiple',
                  defaultMessage: 'items',
                })
              : formatMessage({ id: 'plp.product.number', defaultMessage: 'item' })}
          </div>

          <div
            ref={selectedFiltersRef}
            className={classNames(styles.selectedFilters, {
              [styles.selectedFilters__collapsed]: isResultExpandable && !isResultExpanded,
            })}
          >
            {Object.keys(selectedFilters)
              .map((k) => selectedFilters[k].map((f) => ({ attribute: k, term: f })))
              .flat()
              .map(({ attribute, term }, index) => (
                <button
                  key={index}
                  className={styles.selectedFilter}
                  onClick={() => handleFilterClick(attribute, term)}
                >
                  {term}
                  <CloseIcon className={styles.removeFilter} />
                </button>
              ))}

            <div
              className={classNames(styles.btnContainer, {
                [styles.btnContainer__collapsed]: isResultExpandable && !isResultExpanded,
              })}
              style={getButtonStyle()}
            >
              {isResultExpandable && (
                <button onClick={() => setIsResultExpanded(!isResultExpanded)}>
                  {isResultExpanded
                    ? formatMessage({ id: 'plp.btn.show.less', defaultMessage: 'show less' })
                    : `${formatMessage({ id: 'plp.btn.show.more', defaultMessage: 'show more' })} (${
                        Object.values(selectedFilters).flat().length
                      })`}
                </button>
              )}
              {Object.values(selectedFilters).flat().length > 1 && (
                <button onClick={handleClearFiltersClick}>
                  {formatMessage({ id: 'plp.btn.clear.all', defaultMessage: 'clear all' })}
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {!layout.isLargeDesktop && (
        <Drawer
          isOpen={!!openedDrawer}
          backdropClick={() => {
            setOpenedDrawer(undefined);
            setOpenedFilter(null);
          }}
        >
          <span className={styles.drawer}>
            <div className={styles.content}>
              <div className={styles.heading}>
                <h1>
                  {openedDrawer === 'filter'
                    ? formatMessage({ id: 'plp.filters', defaultMessage: 'filters' })
                    : formatMessage({ id: 'plp.sort', defaultMessage: 'sort by' })}
                </h1>
              </div>

              <ul className={styles.drawerFilters}>
                {openedDrawer === 'filter'
                  ? filterIdentifiers.map(({ identifier: attrIdentifier, text }) => (
                      <li key={attrIdentifier} onClick={() => toggleFilterDropdown(attrIdentifier)}>
                        <span>
                          {text +
                            (selectedFilters[attrIdentifier]?.length
                              ? ` (${selectedFilters[attrIdentifier].length})`
                              : '')}
                        </span>
                        <ChevronIcon className={styles.chevron} />
                      </li>
                    ))
                  : sortOptions.map((option, index) => (
                      <li key={index} onClick={() => handleSortClick(option)}>
                        {formatMessage(option.label)}
                      </li>
                    ))}
              </ul>
            </div>

            {filterIdentifiers.map(({ identifier: attrIdentifier, text }) => (
              <div
                key={attrIdentifier}
                className={classNames(styles.drawerFilterOptions, {
                  [styles.drawerFilterOptions__open]: openedFilter === attrIdentifier,
                })}
              >
                <div className={styles.heading} onClick={() => setOpenedFilter(null)}>
                  <ChevronIcon className={styles.chevron} />
                  <h1>{text}</h1>
                </div>

                <ul>
                  {filteredFacets
                    .find((facet) => facet.identifier === `attributes.${attrIdentifier}`)
                    ?.terms.filter(({ count }) => count > 0)
                    .map(({ identifier: termIdentifier, key, label, count }) => (
                      <li
                        key={key}
                        className={styles.filterOption}
                        onClick={() => handleDrawerFilterClick(attrIdentifier, termIdentifier)}
                      >
                        <label htmlFor={key}>{`${transformLabel(attrIdentifier, label)} (${count})`}</label>
                        <input
                          checked={drawerFilters[attrIdentifier]?.includes(termIdentifier)}
                          type="checkbox"
                          name={attrIdentifier}
                          value={key}
                          onChange={() => {}}
                        />
                      </li>
                    ))}
                </ul>
              </div>
            ))}

            {openedDrawer === 'filter' && (
              <div className={styles.drawerBtns}>
                {(Object.values(drawerFilters).flat().length > 1 ||
                  (openedFilter && !!drawerFilters[openedFilter].length)) && (
                  <button
                    className={styles.btnClear}
                    onClick={
                      openedFilter && drawerFilters[openedFilter].length
                        ? () => handleDrawerClearClick(openedFilter)
                        : handleClearFiltersClick
                    }
                  >
                    {openedFilter && drawerFilters[openedFilter].length
                      ? formatMessage({ id: 'plp.btn.clear', defaultMessage: 'clear' })
                      : formatMessage({ id: 'plp.btn.clear.all', defaultMessage: 'clear all' })}
                  </button>
                )}
                <button className={styles.btnApply} onClick={handleApplyFiltersClick}>
                  {formatMessage({ id: 'plp.btn.drawer', defaultMessage: 'close and view products' })}
                </button>
              </div>
            )}
          </span>
        </Drawer>
      )}
    </div>
  );
};

export default Filters;
