import { Filters } from '..';
import { TermFacet } from '../../../../../types/result/TermFacet';
import { optionValuesOrder } from '../../products/product-details/utils';

export const getFilteredFacets = (facets: TermFacet[], selectedFilters?: Filters) => {
  return facets
    .filter(
      (f) =>
        f.terms?.length > 1 ||
        Object.keys(selectedFilters ?? {})
          .filter((key) => selectedFilters[key].length)
          .some((key) => f.identifier.includes(key)),
    )
    .map((facet) => {
      if (facet.identifier !== 'attributes.TP_SIZE') return facet;

      let sortedTerms = [...facet.terms].sort((t1, t2) => {
        if (isNaN(parseFloat(t1.label))) return 1;
        if (isNaN(parseFloat(t2.label))) return -1;

        const a = parseFloat(t1.label);
        const x = t1.label.length;
        const b = parseFloat(t2.label);
        const y = t2.label.length;

        return a === b ? (x === y ? 0 : x > y ? 1 : -1) : a > b ? 1 : -1;
      });

      sortedTerms = [...sortedTerms].sort((t1, t2) => {
        if (!isNaN(parseFloat(t1.label))) return 1;
        if (!isNaN(parseFloat(t2.label))) return 1;

        let a = optionValuesOrder.indexOf(t1.label.toUpperCase());
        let b = optionValuesOrder.indexOf(t2.label.toUpperCase());

        a = a === -1 ? 100 : a;
        b = b === -1 ? 100 : b;

        return a > b ? 1 : b > a ? -1 : 0;
      });

      return {
        ...facet,
        terms: sortedTerms,
      };
    });
};
