export const sortOptions = [
  {
    name: 'price',
    value: 'asc',
    label: { id: 'priceAsc', defaultMessage: 'Price (ascending)' },
  },
  {
    name: 'price',
    value: 'desc',
    label: { id: 'priceDesc', defaultMessage: 'Price (descending)' },
  },
  {
    name: 'createdAt',
    value: 'desc',
    label: { id: 'createdAtDesc', defaultMessage: 'New in' },
  },
];
