import { Filters } from '..';
import { getFilterIdentifiers } from './getFilterIdentifiers';

type Query = {
  [key: string]: any;
};

export const getFiltersFromQuery = (query: Query): Filters => {
  const filters = {};

  getFilterIdentifiers().forEach(({ identifier }) => {
    if (Object.keys(query).includes(`facets[attributes.${identifier}][terms]`)) {
      filters[identifier] = query[`facets[attributes.${identifier}][terms]`].split(',');
    } else {
      filters[identifier] = [];
    }
  });

  return filters;
};
