import { Sort } from '..';
import { sortOptions } from '../constants';

type Query = {
  [key: string]: string | string[];
};

export const getSortFromQuery: (query: Query) => Sort = (query) => {
  for (const option of sortOptions) {
    if (
      Object.keys(query).includes(`sortAttributes[0][${option.name}]`) &&
      option.value == query[`sortAttributes[0][${option.name}]`]
    ) {
      return option;
    }
  }
};
