import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { AlgoliaProduct, Product } from '@Types/product/Product';
import { TermFacet } from '@Types/result/TermFacet';
import Filters from 'components/commercetools-ui/filters';
import TagManager from 'helpers/googleTagManager/googleTag';
import { useFormat } from 'helpers/hooks/useFormat';
import { useStorage } from 'helpers/hooks/useStorage';
import { LayoutContext } from 'frontastic/provider/layout';
import { getProductsByQuery } from '../../../../frontastic/actions/algolia';
import List from './list';
import styles from './product-list.module.scss';

export interface ProductListProps {
  products: AlgoliaProduct[];
  facets: TermFacet[];
  totalProductsNum: number;
  query: {
    category: string;
    query: string;
    sortAttributes: any;
    facets: any;
  };
  queryID: string;
  indexName: string;
}

const PAGE_SIZE = 36;
const SSR_THRESHOLD_PAGE = 10;
const ProductList: FC<ProductListProps> = ({ products, totalProductsNum, facets, query, queryID, indexName }) => {
  const tagManager = new TagManager();
  const router = useRouter();
  const layout = useContext(LayoutContext);
  const [displayedProducts, setDisplayedProducts] = useState(products);
  const [currentPage, setCurrentPage] = useState(+(router?.query?.page ?? 1));
  const plpRef = useRef(null);
  const { setItem, getItem } = useStorage();
  const { formatMessage } = useFormat({ name: 'product' });
  const [gridLayout, setGridLayout] = useState(layout.isMobile ? 1 : 4);
  const shouldShowNavigation = displayedProducts.length < totalProductsNum;
  let productIncrement = PAGE_SIZE;
  if (productIncrement + displayedProducts.length > totalProductsNum) {
    productIncrement = totalProductsNum - displayedProducts.length;
  }
  const viewItemListFunction = () => {
    tagManager.viewItemList(
      displayedProducts,
      query?.category ?? 'Search > ' + query?.query,
      currentPage,
      productIncrement,
    );
  };

  useEffect(() => {
    if (router.query?.page && +router.query.page > SSR_THRESHOLD_PAGE) {
      getPlpProducts((+router.query.page - SSR_THRESHOLD_PAGE) * PAGE_SIZE).then((newProducts) => {
        setDisplayedProducts([...displayedProducts, ...newProducts]);
      });
    }

    window.onload = viewItemListFunction;

    return () => {
      window.onload = null;
    };
  }, []);

  useEffect(() => {
    setItem('queryID', queryID, 'session');
    setItem('indexName', indexName, 'session');
  }, [queryID, indexName]);

  useEffect(() => {
    const lastVisitedPlp = getItem('lastVisitedPlp', 'session');
    const lastVisitedProductId = getItem('lastVisitedProductId', 'session');

    let observer: MutationObserver;
    if (lastVisitedPlp === window.location.href && lastVisitedProductId) {
      if (document.getElementById(lastVisitedProductId)) {
        document.getElementById(lastVisitedProductId)?.scrollIntoView({ block: 'center', inline: 'nearest' });
      } else {
        observer = new MutationObserver(() => {
          if (document.getElementById(lastVisitedProductId)) {
            document.getElementById(lastVisitedProductId)?.scrollIntoView({ block: 'center', inline: 'nearest' });
            observer.disconnect();
          }
        });

        observer.observe(plpRef.current, {
          childList: true,
          subtree: true,
        });
      }
    } else {
      setItem('lastVisitedPlp', window.location.href, 'session');
    }

    return () => {
      observer?.disconnect();
    };
  }, [gridLayout]);

  useEffect(() => {
    const lastVisitedPlp = getItem('lastVisitedPlp', 'session');
    if (!lastVisitedPlp || lastVisitedPlp !== window.location.href) {
      setItem('lastVisitedPlp', window.location.href, 'session');
    }
  }, [router.asPath]);

  useEffect(() => {
    if (currentPage != +(router?.query?.page ?? 1)) {
      setCurrentPage(+(router?.query?.page ?? 1));
    }
  }, [JSON.stringify({ ...(router?.query ?? {}), page: 'ignore' })]);

  useEffect(() => {
    setDisplayedProducts(products);
  }, [JSON.stringify(products)]);

  useEffect(() => {
    viewItemListFunction();
  }, [displayedProducts]);

  const getPlpProducts = async (length?: number) => {
    const requestQueryParams = {
      query: query.query,
      category: query.category,
      page: +router.query.page || 1,
      ...(length ? { length: length, offset: SSR_THRESHOLD_PAGE * PAGE_SIZE } : {}),
    };

    Object.keys(router.query).forEach((key) => {
      if (key.startsWith('facets') || key.startsWith('sortAttributes')) {
        requestQueryParams[key] = router.query[key];
      }
    });
    return await getProductsByQuery(requestQueryParams);
  };

  const onLoadClick = async () => {
    const newProducts = await getPlpProducts();

    updatePageQueryAttr();
    setCurrentPage(currentPage + 1);
    setDisplayedProducts([...displayedProducts, ...newProducts]);
  };

  const updatePageQueryAttr = () => {
    const updatedUrlQueryParams = { ...router.query };
    delete updatedUrlQueryParams.locale;
    if (updatedUrlQueryParams.hasOwnProperty('path') && !updatedUrlQueryParams.hasOwnProperty('slug')) {
      updatedUrlQueryParams.slug = [(updatedUrlQueryParams.path as string).replace('/', '')];
      delete updatedUrlQueryParams['path'];
    }
    updatedUrlQueryParams.page = (currentPage + 1).toString();

    router.push(
      {
        pathname: router.pathname,
        query: updatedUrlQueryParams,
      },
      undefined,
      { shallow: true },
    );
  };

  const navigationBtnText = formatMessage({
    id: 'plp.navigation.remaining',
    defaultMessage: 'Load _ more products +',
  }).replace('_', productIncrement.toString());

  const changeGridLayout = (viewportChange = false) => {
    if (layout.isMobile) {
      viewportChange || gridLayout === 2 ? setGridLayout(1) : setGridLayout(2);
    } else {
      viewportChange || gridLayout === 3 ? setGridLayout(4) : setGridLayout(3);
    }
  };

  useEffect(() => {
    changeGridLayout(true);
  }, [layout.isMobile]);

  return (
    <div ref={plpRef} className={styles.container}>
      <Filters
        facets={facets}
        numOfItems={totalProductsNum}
        toggleGridLayout={changeGridLayout}
        gridLayout={gridLayout}
      />
      <List products={displayedProducts} gridLayout={gridLayout} query={query} />

      <div className={styles.navigation}>
        <p>{`${displayedProducts.length}/${totalProductsNum}`}</p>
        {shouldShowNavigation && <button onClick={onLoadClick}>{navigationBtnText}</button>}
      </div>
    </div>
  );
};

export default ProductList;
