import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import Backdrop from '../../../common/backdrop';
import styles from './drawer.module.scss';

interface DrawerProps {
  isOpen: boolean;
  children: JSX.Element | JSX.Element[];
  backdropClick?: () => void;
}
const Drawer: FC<DrawerProps> = ({ isOpen, children, backdropClick }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return typeof window !== 'undefined' ? (
    createPortal(
      <>
        <Backdrop
          className={classNames(styles.backdrop, { [styles.backdrop__open]: isOpen })}
          onClick={backdropClick}
        />
        <div
          className={classNames(styles.container, { [styles.container__open]: isOpen })}
          aria-hidden={!isOpen}
          data-testid="drawer_children"
        >
          {children}
        </div>
      </>,
      document.getElementById('__next'),
    )
  ) : (
    <></>
  );
};

export default Drawer;
